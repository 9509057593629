export const clockInBeforeOneHourError = "Clock-in cannot exceed one hour before posted start time";
export const earlyClockInIsDisabledError =
  "This shift does not pay for work before a shift’s start time";
export const clockInAfterShiftEndError = "Clock-in cannot be after shift end time";
export const clockOutAfterTwoHoursError = "Clock-out cannot exceed two hours after posted end time";
export const clockOutPostActualClockOut = "Clock-out cannot exceed current time";
export const clockOutBeforeShiftStart = "Clock-out cannot be before shift start time";
export const lunchInBeforeLunchOut = "End Break cannot be before Start Break";
export const clockOutBeforeClockIn = "Clock-in cannot be after clock-out";
export const eventTimeNotInOrder = "Event times must be in order";
export const clockInEmpty = "Enter clock-in time";
export const clockOutEmpty = "Enter clock-out time";
export const clockInOutEmpty = "Enter clock-in and clock-out times";
export const lunchInOutEmpty = "Enter break time";
export const clockInRadarError =
  "You cannot edit your Clock-In time to before we recorded you entering the facility at %s using location-tracking.";
export const breakStartRadarError =
  "You cannot edit your Break-Start time to after we recorded you left the facility at %s using location-tracking.";
export const breakEndRadarError =
  "You cannot edit your Break-End time to before we recorded you re-entering the facility at %s using location-tracking.";
