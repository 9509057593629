export enum FacilityNoteIdentifier {
  CHECK_IN_INST = "CHECK_IN_INS",
  CHECK_IN_INST_PARKING = "CHECK_IN_INS_PARKING",
  CHECK_IN_INST_ENTRANCE = "CHECK_IN_INS_ENTRANCE",
  CHECK_IN_INST_ORIENTATION = "CHECK_IN_INS_ORIENTATION",
  CHECK_IN_INST_EARLYCHECKIN = "CHECK_IN_INS_EARLYCHECKIN",
  CHECK_IN_INST_FIRSTSHIFT = "CHECK_IN_INS_FIRSTSHIFT",
  CHECK_IN_INST_TIMECARD = "CHECK_IN_INS_TIMECARD",
  CHECK_IN_INST_DRESS = "CHECK_IN_INS_DRESS",
  CHECK_IN_INST_OTHER = "CHECK_IN_INS_OTHER",
  PROF_DESC = "PROFILE_DESC",
  MANDATORY_BREAK_POLICY = "MANDATORY_BREAK_POLICY",
}
