export const DEFAULT_ERROR_MESSAGE = "Sorry, something went wrong. Please try again!";
export const STRIPE_ERROR_MESSAGE = "Could not start Stripe session. Please try again later!";
export const STRIPE_UPLOAD_CANCELLED = "You have cancelled Stripe upload";
export const STRIPE_CONSENT_DECLINED = "You declined consent, please try again";

export const DRIVING_LICENSE_STATE_ID_FILE_NAME = "driver_s_license_or_state_id";

export const DRIVING_LICENSE_STATE_ID_PLACEHOLDER_ROOT_KEY = "document-storage-service-cbh-dev";
export const DRIVING_LICENSE_STATE_ID_PLACEHOLDER_FILE_KEY =
  "635a84f62ebfd88fddceb269/5baabbd23b089e0004880e55/a2de0370-3dc1-4a54-9046-cf2c8ef59e76.jpeg";

export const DRIVING_LICENSE_STATE_ID_REQUIREMENT_ID = "5b932fcb38aeaa0014c3688f";

export const STRIPE_IDENTIFY_SDK = "STRIPE_IDENTIFY_SDK";

export const PERSONAL_ID_CUSTOM_DESCRIPTION =
  "Upload your Driver's License or another type of ID document.";

export const PERSONAL_ID = "Personal ID";
