/* eslint-disable max-len */
/* eslint-disable no-template-curly-in-string */
const MessageString = {
  importantInformationFromHCFDisclaimerText:
    "Important information is not verified by Clipboard Health for accuracy, and may be incorrect or misleading.",
  payrollSummary: {
    payoutSuccessful: "Payout has been sent successfully!",
    payoutFailedForUnknownReason: "Payout failed. Please contact support team for assistance",
  },
};
export const ErrorMessages = {
  PROFILE_IMAGE_FAILED_NO_USER_ID_SUPPLIED: {
    code: "ERR_00001",
    message: "Profile Image Upload Failed because no userId was provided ",
  },
  FILE_EXTENSION_INVALID: {
    code: "ERR_00002",
    message: "File extension is invalid",
  },
};
export { MessageString };
