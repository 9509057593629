import { NegotiationCommitType } from "@src/app/rateNegotiation/interfaces";

const REMOVE_LICENSE_MODAL = {
  TITLE: "Remove License",
  SUBTITLE:
    "All booked shifts in this state will be cancelled, which may result in Attendance Score point deductions.",
  CONTENT:
    "Are you sure you want to remove this license from your account? This means you will no longer be able to work shifts in this state.",
};

const SHIFT_QUALIFICATION_MODAL = {
  TITLE: "Qualification",
  CONTENT: "Choose which kinds of shifts you want to see, based on your active",
};

const RATE_NEGOTIATION_COMMIT_MODAL = {
  [NegotiationCommitType.PROPOSE_NEW_RATE]: {
    TITLE: "Propose New Rate",
    CONTENT: (rate) =>
      `If the workplace accepts your proposal you will be booked for this shift. Do you want to propose that the rate for this shift should be $${rate}/hr?`,
    SUB_CONTENT: "You can book the shift at the current posted rate at any time.",
    BUTTON_TEXT: "Propose Rate",
  },
  [NegotiationCommitType.PROPOSE_NEW_TIME]: {
    TITLE: "Propose New Time",
    CONTENT: (formattedTimeText) =>
      `Do you want to propose that the time for this shift should be ${formattedTimeText}? The Workplace can accept this time and book the shift for you or end this negotiation.`,
    SUB_CONTENT: "You can book the shift at the current posted time.",
    BUTTON_TEXT: "Propose Time",
  },
  [NegotiationCommitType.END_NEGOTIATION]: {
    TITLE: "End Negotiation",
    CONTENT:
      "When you end a negotiation you won't be able to propose another rate for the same shift. Are you sure you want to end this shift rate negotiation?",
    SUB_CONTENT: "You will still be able to book the shift at the Workplace's posted rate.",
    BUTTON_TEXT: "End Negotiation",
  },
};
export { REMOVE_LICENSE_MODAL, SHIFT_QUALIFICATION_MODAL, RATE_NEGOTIATION_COMMIT_MODAL };
