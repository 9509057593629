export * from "./userEvents";
export * from "./shift";

export const DISTANCE_PREFERENCE_MAX_VALUE_IN_MILES = 150;

export const CHECKR_REQUIREMENT_ID = "5b9327c238aeaa0014c3688d";

export const STATE_LICENSE_REQUIREMENT_ID = "5be9750b06c86c0004a1deca";

export enum SEARCH_MODE {
  CALENDAR = "CALENDAR",
  DATE = "DATE",
  TIME_SLOT = "TIME_SLOT",
  MAP = "MAP",
  HCF_PROFILE = "HCF_PROFILE",
  URGENT_SHIFTS = "URGENT_SHIFTS",
  NEGOTIATING_SHIFTS = "NEGOTIATING_SHIFTS",
  NON_QFA_RANGE_SHIFTS = "NON_QFA_RANGE_SHIFTS",
  WORKER_AVAILABILITY = "WORKER_AVAILABILITY",
}

export const HCF_PROFILE_SOURCE = {
  CALENDAR: "CALENDAR",
  MAP: "MAP",
  WORKER_AVAILABILITY: SEARCH_MODE.WORKER_AVAILABILITY,
};

export const FACILITY_OPEN_SHIFTS_DATE_RANGE_DAYS = 30;

export const REFERRALS = {
  REFER_A_FRIEND: "Refer a Friend",
  REDEEM_REFERRAL_CODE: "Redeem Code",
  REFER_AND_EARN: "Refer & Earn",
};
