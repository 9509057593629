export const SHIFT_STRINGS = {
  ARE_YOU_SURE_YOU_WANT_TO_SKIP_BREAK:
    "Are you sure you want to skip your break? Most facilities require that you take a break during your shift.",
  ARE_YOU_SURE_YOU_WANT_TO_SKIP_MANDATORY_BREAK:
    "This facility requires a 30-minute break. Are you sure you want to skip it?",
  CANCEL_SKIP_BREAK: "Cancel",
  SKIP_BREAK: "Skip Break",
};

export const NFC_SCAN_MAX_TIME = 10000;
export const EXTENDED_NFC_SCAN_MAX_TIME = 20000;
export const EXTENDED_NFC_SCAN_MAX_TIME_IN_SECONDS = EXTENDED_NFC_SCAN_MAX_TIME / 1000;
