import { getWorkerResponseSchema } from "@src/appV2/Worker/api/types";
import { z } from "zod";
const workerTypeToLabel = {
  CNA: {
    label: "CNA",
    value: "CNA",
  },
  LVN: {
    label: "LVN / LPN",
    value: "LVN",
  },
  RN: {
    label: "RN",
    value: "RN",
  },
  NP: {
    label: "NP",
    value: "NP",
  },
  CAREGIVER: {
    label: "CAREGIVER",
    value: "CAREGIVER",
  },
  PACU_RN: {
    label: "PACU RN",
    value: "PACU RN",
  },
  OR_RN: {
    label: "OR RN",
    value: "OR RN",
  },
  SPD_TECH: {
    label: "SPD TECH",
    value: "SPD TECH",
  },
  SURG_TECH: {
    label: "SURG TECH",
    value: "SURG TECH",
  },
  CRNA: {
    label: "CRNA",
    value: "CRNA",
  },
  PHLEBOTOMIST: {
    label: "Phlebotomist",
    value: "Phlebotomist",
  },
  MLT: {
    label: "MLT",
    value: "MLT",
  },
  MEDICAL_ASSISTANT: {
    label: "Medical Assistant",
    value: "Medical Assistant",
  },
  PT: {
    label: "PT",
    value: "PT",
  },
  PTA: {
    label: "PTA",
    value: "PTA",
  },
  HHA: {
    label: "HHA",
    value: "HHA",
  },
  EMT: {
    label: "EMT",
    value: "EMT",
  },
  RESPIRATORY_THERAPIST: {
    label: "Respiratory Therapist",
    value: "Respiratory Therapist",
  },
  PARAMEDIC: {
    label: "Paramedic",
    value: "Paramedic",
  },
  NON_CLINICAL: {
    label: "Non Clinical",
    value: "Non Clinical",
  },
  PHARM_TECH: {
    label: "Pharm Tech",
    value: "Pharm Tech",
  },
  MEDICAL_TECHNICIAN: {
    label: "Medical Technician",
    value: "Medical Technician",
  },
};

export function getWorkerShiftTypeLabel(
  shiftType: z.infer<typeof getWorkerResponseSchema>["otherQualification"] = ""
): string {
  return workerTypeToLabel[shiftType ?? ""]?.label ?? shiftType;
}
